Dropzone.options.galleryForm = {
    maxFilesize: 4,
    acceptedFiles: '.jpg, .jpeg, .png, .bmp, .gif'
}

jQuery(document).ready(function($){
    
    /*
    |--------------------------------------------------------------------------
    | Plugins init
    |--------------------------------------------------------------------------
    */

    $('a.lightbox').lightbox({
        mobile: true
    });

    $("#wine-list").select2({
        placeholder: 'Kliknite da odaberete vino.'
    });

    $("#location-list").select2({
        placeholder: 'Kliknite da odaberete POI.'
    });

    $("#top-list").select2({
        placeholder: 'Kliknite da odaberete POI.'
    });


    /*
    |--------------------------------------------------------------------------
    | Flash message
    |--------------------------------------------------------------------------
    */
   
    $('.callout.success').delay(2000).slideUp();


    /*
    |--------------------------------------------------------------------------
    | Delete button alert
    |--------------------------------------------------------------------------
    */

    $('.delete-button, .table-delete-button').click(function(e){
        if(!confirm('Jeste li sigurni?')){
           e.preventDefault();
        }
    })

    /*
    |--------------------------------------------------------------------------
    | Show/Hide Gastro Data or winary
    |--------------------------------------------------------------------------
    */
    function showHideGastroWinary(){
        var locationType = $('.location-type-select select').val();
        
        if(locationType == 1 || locationType == 3 || locationType == 4 || locationType == 7 || locationType == 8){
            $('.gastro').show();
            if(locationType == 3){
                $('.ocjena').show();
            } else {
                $('.ocjena').hide();
            }
        } else {
            $('.gastro').hide();
        }

        if( locationType == 1 ) {
            $('.winary').show();
        } else {
            $('.winary').hide();
        }

        if( locationType == 4 ) {
            $('.oilmill').show();
        } else {
            $('.oilmill').hide();
        }

        if( locationType == 4 || locationType == 1){
            $('.meal-types').hide();
            $('.category').hide();
            $('.wine-list').hide();
            $('.seats').text('Kušaona - broj mjesta:');
        } else {
            $('.meal-types').show();
            $('.category').show();
            $('.wine-list').show();
            $('.seats').text('Broj sjedećih mjesta:');
        }
    }

    showHideGastroWinary();
   

    $('.location-type-select select').change(function(){
        showHideGastroWinary();
    });
});